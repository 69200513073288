<template>
  <div class="box">
    <div class="top">
      <div class="box1">
        <div>蟹券编号 : {{ ticketNo }}</div>
        <div>蟹券密码 : {{ ticketPasswd }}</div>
      </div>
      <div class="box2">
        <div class="ul">
          <div>收 &nbsp;件 &nbsp;人 :</div>
          <input
            v-model="contactName"
            type="text"
            placeholder="请输入姓名"
            placeholder-class="placeholderStyle"
          />
        </div>
        <div class="ul">
          <div>收件电话 :</div>
          <input
            v-model="contactPhone"
            type="text"
            placeholder="请输入电话"
            placeholder-class="placeholderStyle"
          />
        </div>
        <div class="ul" @click.stop="show1 = true">
          <div>收件地区 :</div>
          <input
            v-model="add"
            type="text"
            placeholder="请选择省、市、区"
            placeholder-class="placeholderStyle"
          />
        </div>
        <div class="ul">
          <div>详细地址 :</div>
          <input
            v-model="receiveAddress"
            type="text"
            placeholder="请输入详细地址"
            placeholder-class="placeholderStyle"
          />
        </div>
        <div class="ul">
          <div>备用电话 :</div>
          <input
            v-model="backupPhone"
            type="text"
            placeholder="请输入备用电话"
            placeholder-class="placeholderStyle"
          />
        </div>
        <div class="ul" @click="show = true">
          <div>预约发货 :</div>
          <input
            v-model="subscribeTime"
            type="text"
            placeholder="请选择发货时间"
            placeholder-class="placeholderStyle"
          />
        </div>
      </div>
      <div class="box3">
        <div>备注</div>
        <textarea rows="4" v-model="exchangeRemark"></textarea>
      </div>
      <div class="box4" @click="submit">立即兑换</div>
    </div>
    <div class="bom">
      <img src="../assets/image/1.png" alt="" />
      <img class="right" src="../assets/image/5.png" alt="" />
    </div>
    <img class="kf" src="../assets/image/6.png" alt="" @click="show2 = true" />

    <van-action-sheet v-model="show">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="发货时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirm"
        @cancel="show = false"
      />
    </van-action-sheet>
    <van-action-sheet v-model="show1">
      <van-area
        title="选择地址"
        @confirm="selectadd"
        @cancel="show1 = false"
        :area-list="areaList"
      />
    </van-action-sheet>
    <van-action-sheet v-model="show2">
      <van-picker
        title="客服列表"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show2 = false"
      >
        <template #title>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              style="
                color: #333;
                font-size: 18px;
                font-weight: 600;
                margin: 5px 0;
              "
            >
              客服列表
            </div>
            <div style="color: #999; font-size: 12px">工作时间：09点~17点</div>
          </div>
        </template>
        <template #confirm><div style="color: #387aff">拨号</div></template>
      </van-picker>
    </van-action-sheet>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data'
import { getMinDate, getMaxDate, getDefaultMessage, exchangeCrabTicket } from '../api/user'
import moment from 'moment'
import { Toast, Dialog } from 'vant'
export default {
  data() {
    return {
      columns: ['4008861797', '027-88013866', '027-88011709'],
      show2: false,
      ticketNo: '',
      ticketPasswd: '',
      areaList,
      show1: false,
      show: false,
      minDate: new Date(),
      maxDate: new Date(),
      currentDate: new Date(),
      backupPhone: '',
      contactName: '',
      contactPhone: '',
      exchangeRemark: '',
      receiveAddress: '',
      add: '',
      subscribeTime: '',
      messageTitle: ''
    }
  },
  created() {
    const time =
      String(moment(new Date()).format('yyyy-MM-DD HH:ss:mm')).slice(11, 13) * 1
    console.log(time)
    if (time >= 17) {
      // this.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      this.currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    }
    this.ticketPasswd = this.$route.query.pass
    this.ticketNo = this.$route.query.id
    this.getDefaultMessage()
    this.getMaxDate()
    this.getMinDate()
  },
  methods: {
    getDefaultMessage() {
      getDefaultMessage({}).then((res) => {
        this.messageTitle = res.data.data.messageTitle
      })
    },
    getMaxDate() {
      getMaxDate({}).then((res) => {
        this.maxDate = new Date(res.data.data.messageTitle)
      })
    },
    getMinDate() {
      getMinDate({}).then((res) => {
        this.minDate = new Date(res.data.data.messageTitle)
      })
    },
    onConfirm(e) {
      this.show2 = false
      window.location.href = 'tel:' + e
    },
    selectadd(e) {
      let str = ''
      e.forEach((res) => {
        str += res.name + '/'
      })
      this.add = str.substring(0, str.length - 1)
      this.show1 = false
    },
    confirm(e) {
      this.subscribeTime = moment(e).format('yyyy-MM-DD')
      Toast({
        message: this.messageTitle,
        duration: 6000
      })
      this.show = false
    },
    async submit() {
      if (!this.contactName) {
        Toast('请填写收件人')
      } else if (!this.contactPhone) {
        Toast('请填写收件电话')
      } else if (!this.add) {
        Toast('请选择省市区')
      } else if (!this.receiveAddress) {
        Toast('请填写详细地址')
      } else if (!this.subscribeTime) {
        Toast('请选择发货时间')
      } else {
        const obj = {
          backupPhone: this.backupPhone ? this.backupPhone : this.contactPhone,
          contactName: this.contactName,
          contactPhone: this.contactPhone,
          exchangeRemark: this.exchangeRemark,
          receiveAddress:
            this.add.split('/')[0] +
            this.add.split('/')[1] +
            this.add.split('/')[2] +
            this.receiveAddress,
          subscribeTime: this.subscribeTime,
          ticketNo: this.ticketNo,
          ticketPasswd: this.ticketPasswd
        }
        const obj1 = {}
        for (const k in obj) {
          if (obj[k]) {
            obj1[k] = obj[k]
          }
        }
        const { data } = await exchangeCrabTicket(obj1)
        console.log(data)
        if (data.code === 200) {
          Dialog.alert({
            title: '兑换成功',
            message:
              '以当日签收验货时效为准，错过验收时效概不理赔。',
            theme: 'round-button'
          }).then(() => {
            this.$router.go(-1)
          })
        } else {
          Toast(data.msg)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-picker__toolbar {
  height: 55px;
}
.kf {
  width: 70px;
  height: 70px;
  position: fixed;
  right: -10px;
  bottom: 20vh;
}
.box {
  border: none;
  min-height: 100vh;
  background-color: #6a201d;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.top {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 84vw;
  margin-left: 8vw;
  margin-top: 30px;
  height: 560px;
  background-image: url('../assets/image/14.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #dfab72;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #dfab72;
    font-weight: 400;
    opacity: 0.6;
  }
  .box1 {
    width: 98%;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px 15px;
    box-sizing: border-box;
    line-height: 30px;
    border-bottom: 1px dashed #dfab72;
  }
  .box2 {
    width: 98%;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .ul {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    input {
      border: none;
      outline: none;
      background-color: rgba(255, 255, 255, 0);
      border-bottom: 1px solid #dfab72;
      line-height: 24px;
    }
    div {
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .box3 {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    width: 98%;
    padding: 0 20px 0 15px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      border: 1px solid #ee3e00;
      outline: none;
      border-radius: 10px;
      padding: 5px 10px;
      box-sizing: border-box;
      margin-top: 10px;
    }
  }
  .box4 {
    line-height: 35px;
    text-align: center;
    color: #fff;
    background-color: #dfab72;
    border-radius: 35px;
    width: 80%;
    font-weight: 14px;
    margin-top: 13px;
  }
}
.bom {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  img {
    height: 58vw;
    width: 58vw;
  }
  .right {
    width: 40vw;
    height: 48vw;
  }
}
</style>
